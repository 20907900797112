import { Col, Input, Layout, Row, Form, Typography, Card, Button, Table, Empty, Popconfirm, Select, Image } from 'antd';
import { ClearOutlined, FileSearchOutlined, PrinterOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Content } from 'antd/es/layout/layout';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb';
import { IconViewMore, IconSearch, IconDelete2 } from '../../helper/icon';
import userService from '../../server/service/user';
import logService from '../../server/service/log';
const { Column } = Table;
const { Option } = Select;

const UserIndexPage = () => {
    const navigate = useNavigate();
    const defaultPageSize = 10;
    const [keyword, setKeyword] = useState('');
    const [role, setRole] = useState(null);
    const [companyId, setCompanyId] = useState(null);
    const [status, setStatus] = useState(null);

    const [filter, setFilter] = useState({ keyword: null, role: null, companyId: null, status: null });
    const [paging, setPaging] = useState({ item_per_page: defaultPageSize, page_no: 1 });
    const [totalItem, setTotalItem] = useState(0);

    const [isLoading, setIsLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [departmets, setDepartments] = useState([]);

    useEffect(() => {
        (async () => {
            await logService.log(logService.LogType.User, "ดูรายการ");

            var res = await userService.getDepartments();

            // console.log({ res });

            var temp = [{ value: null, label: 'ทั้งหมด' }];
            for (var i = 0; i < res.length; i++) {
                temp.push({ value: res[i].company_id, label: res[i].thai_name })
            }
            setDepartments(temp);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                var res = await userService.getList(filter, paging.item_per_page, paging.page_no);

                // console.log({ res });

                setUsers(res.data);
                setTotalItem(res.paging.total_item);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [filter, paging]);

    const breadcrumbList = [
        { title: `จัดการผู้ใช้งาน`, link: `` },
        { title: `รายการผู้ใช้งาน`, link: `` },
    ]

    const onSearchHandle = () => {
        setFilter({ keyword: keyword, role: role, companyId: companyId, status: status });
    }

    const onClearHandle = () => {
        setKeyword('');
        setRole(null);
        setCompanyId(null);
        setStatus(null);
        setFilter({ keyword: null, role: null, companyId: null, status: null });
    }

    const onShowSizeChange = (current, size) => {
        setPaging({ page_no: 1, item_per_page: size });
    }

    const onChangePage = (page, pageSize) => {
        setPaging({ page_no: page, item_per_page: pageSize });
    }

    const onEditHandle = (id) => {
        if (id) {
            navigate(`/user/edit/${id}`, { state: { userId: id } });
        }
    }

    const onDetailHandle = (id) => {
        if (id) {
            navigate(`/user/edit/${id}?isView=true`, { state: { userId: id, isView: true } });
        }
    }

    const onDeleteHandle = (user) => {
        (async () => {
            try {
                await userService.deleteData(user.user_id);

                await logService.log(logService.LogType.User, `ลบผู้ใช้งาน "${user.email}"`);

                onSearchHandle();
            } catch (error) {
                console.error(error);
            }
        })();
    }

    return (
        <Layout style={{ height: 'inherit', width: "100%" }}>
            <Content style={{ padding: '24px', height: '100%' }}>
                <Row gutter={[16, 16]}>
                    <Col style={{ width: "100%" }}>
                        <Breadcrumb items={breadcrumbList} />
                    </Col>
                    <Col style={{ width: "100%", padding: 0, }}>
                        <Card title="รายการผู้ใช้งาน" classNames={{ header: 'ant-card-head-search-report-isc', }}
                            styles={{
                                title: {
                                    fontSize: 20,
                                    color: '#000000',
                                }
                            }}>
                            <Form>
                                <Row gutter={[16, 16]} justify="start" align="bottom" style={{ width: "100%", margin: 0 }}>
                                    <Col span={24}>
                                        <Row gutter={[5, 5]}>
                                            <Col span={6} style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                                <Typography.Text strong>ค้นหา</Typography.Text>
                                                <Input onChange={(e) => setKeyword(e.target.value)} value={keyword} placeholder="-- ระบุคำที่ต้องการค้นหา --" />
                                            </Col>
                                            <Col span={6} style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                                <Typography.Text strong>สิทธิ์การใช้งาน</Typography.Text>
                                                <Select onChange={(val) => setRole(val)} value={role} className='select-scroll' placeholder="-- เลือกสิทธิ์การใช้งาน --" style={{ width: "100%" }} listItemHeight={10} listHeight={250}>
                                                    <Option value={null}>ทั้งหมด</Option>
                                                    <Option value={"admin"}>admin</Option>
                                                    <Option value={"user"}>user</Option>
                                                </Select>
                                            </Col>
                                            <Col span={6} style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                                <Typography.Text strong>หน่วยงาน/บริษัท</Typography.Text>
                                                <Select onChange={(val) => setCompanyId(val)} value={companyId} className='select-scroll' options={departmets} placeholder="-- เลือกหน่วยงาน/บริษัท --" style={{ width: "100%" }} listItemHeight={10} listHeight={250}></Select>
                                            </Col>
                                            <Col span={6} style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                                <Typography.Text strong>สถานะ</Typography.Text>
                                                <Select onChange={(val) => setStatus(val)} value={status} className='select-scroll' placeholder="-- เลือกสถานะ --" style={{ width: "100%" }} listItemHeight={10} listHeight={250}>
                                                    <Option value={null}>ทั้งหมด</Option>
                                                    <Option value={true}>ใช้งานได้</Option>
                                                    <Option value={false}>ปิดการใช้งาน</Option>
                                                </Select>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row gutter={[16, 16]} justify="start" align="middle">
                                            <Col>
                                                <Button type="primary" onClick={onSearchHandle} loading={isLoading} style={{ paddingLeft: '30px', paddingRight: '30px', }} icon={<FileSearchOutlined style={{ fontSize: '20px', color: 'var(--color-white)' }} />}>ค้นหา</Button>
                                            </Col>
                                            <Col>
                                                <Button type="primary" onClick={onClearHandle} loading={isLoading} style={{ background: 'var(--color-gray-primary)', }} icon={<ClearOutlined style={{ fontSize: '20px', color: 'var(--color-white)' }} />}>ล้างการค้นหา</Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col flex="1 1 250px" />
                                </Row>
                            </Form >
                        </Card >
                    </Col>
                    <Col style={{ width: "100%", background: 'var(--color-white)', padding: '32px', }}>
                        <Row gutter={[8, 8]}>
                            <Col style={{ width: "100%", padding: 0, }}>
                                <Table
                                    dataSource={users}
                                    scroll={{ x: true }}
                                    loading={isLoading}
                                    className="full-width-table"
                                    style={{ background: 'var(--color-white)' }}
                                    pagination={{
                                        defaultPageSize: defaultPageSize,
                                        pageSize: paging.item_per_page,
                                        total: totalItem,
                                        disabled: isLoading,
                                        showTotal: (total, range) => `แสดงข้อมูล ${range[0]} - ${range[1]} จาก ${total} รายการ `,
                                        onShowSizeChange: onShowSizeChange,
                                        onChange: onChangePage
                                    }}
                                    locale={{
                                        emptyText: <Empty style={{ padding: '25px 0', }} imageStyle={{ display: 'none', }} description='- ไม่พบข้อมูล -' />,
                                    }}
                                >
                                    <Column title="ชื่อผู้ใช้งาน" dataIndex="email" key="email" align='center' />
                                    <Column title="ชื่อ-สกุล" dataIndex="fullname" key="fullname" />
                                    <Column title="ประเภทผู้ใช้งาน" render={() => "บริษัทประกัน"} />
                                    <Column title="หน่วยงาน/บริษัท" dataIndex="company_name" key="company_name" />
                                    <Column title="สิทธิ์การใช้งาน" dataIndex="role_name" key="role_name" align='center' />
                                    <Column title="สถานะ" dataIndex="status_text" key="status_text" align='center' />
                                    <Column
                                        title="เครื่องมือ"
                                        key="action"
                                        width="10%"
                                        align="center"
                                        render={(text, record) => (
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Button
                                                    type="text"
                                                    style={{
                                                        padding: '0px 5px',
                                                    }}
                                                    onClick={() => onDetailHandle(record.user_id)}
                                                >
                                                    <Image
                                                        width={30}
                                                        src={IconSearch}
                                                        preview={false}
                                                        wrapperStyle={{ verticalAlign: 'sub' }}
                                                        style={{ verticalAlign: 'sub' }}
                                                    />
                                                </Button>
                                                <Button
                                                    type="text"
                                                    style={{
                                                        padding: '0px 5px',
                                                    }}
                                                    onClick={() => onEditHandle(record.user_id)}
                                                >
                                                    <Image
                                                        width={30}
                                                        src={IconViewMore}
                                                        preview={false}
                                                        wrapperStyle={{ verticalAlign: 'sub' }}
                                                        style={{ verticalAlign: 'sub' }}
                                                    />
                                                </Button>
                                                <Popconfirm
                                                    placement="left"
                                                    title="ลบผู้ใช้งาน"
                                                    description={`คุณต้องการลบผู้ใช้งาน "${record.email}" ใช่ หรือไม่?`}
                                                    okText="ใช่"
                                                    cancelText="ไม่"
                                                    onConfirm={() => onDeleteHandle(record)}
                                                    okButtonProps={{ style: { paddingLeft: '30px', paddingRight: '30px' } }}
                                                    cancelButtonProps={{ style: { paddingLeft: '30px', paddingRight: '30px' } }}
                                                >
                                                    <Button
                                                        type="text"
                                                        style={{
                                                            padding: '0px 5px',
                                                        }}
                                                    >
                                                        <Image
                                                            width={30}
                                                            src={IconDelete2}
                                                            preview={false}
                                                            wrapperStyle={{ verticalAlign: 'sub' }}
                                                            style={{ verticalAlign: 'sub' }}
                                                        />
                                                    </Button>
                                                </Popconfirm>
                                            </div>
                                        )}
                                    />
                                </Table>
                            </Col>
                        </Row>
                    </Col >
                </Row >
            </Content >
        </Layout >
    );
};

export default UserIndexPage;

